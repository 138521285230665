import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { closeEnlarged } from './';
import ViewDragger from '../dragger/ViewDragger';
import './stylesEnlarged.scss';


function ViewEnlarged ( { enlarged, closeEnlarged } ) {

  return (
      <CSSTransition className='enlarged' in={ enlarged.isActive } timeout={ 300 } classNames='fade' unmountOnExit>
          <div>
              { enlarged.isActive  
              ? <ViewDragger data={ enlarged.data } />
              : <div></div>
              }
              <button className='enlarged--close-btn' onClick={ closeEnlarged }>CLOSE </button>
          </div>
      </CSSTransition> 
  );

}


const mapStateToProps = (state) => ({
  enlarged: state.enlarged
})

const mapDispatchToProps = (dispatch) => ({
  closeEnlarged: () => dispatch(closeEnlarged())
})


export default connect(mapStateToProps, mapDispatchToProps)(ViewEnlarged)