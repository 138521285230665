
import React from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import ButtonHeaderSubMenu from './ButtonHeaderSubMenu';
import { dataRoutes } from '../../routes/dataRoutes';
import logo from './oakmont-logo-linen.svg';
import './stylesHeader.scss';
import { connect } from 'react-redux';

const ViewHeader = ({ oldItems }) => {    
    const getLinks = () => {
        const links = [];
        let currRoute;
        let currKeyIndex = 0;
        for (let i = 0; i < dataRoutes.length - 1; i++) { //skip home as it's used as the logo
            currRoute = dataRoutes[i];
            if (currRoute.title) { //if there's no title the link is assumed to be accessed elsewhere, outside the primary menu
                links.push(
                    <li className='header--nav__li' key={ currKeyIndex }>
                        {
                        currRoute.routes === undefined //whether it's a drop down or not
                        ? <NavLink className='header--nav__link' activeClassName='header--nav__link-selected' to={ currRoute.path }>{ currRoute.title }</NavLink>
                        : <ButtonHeaderSubMenu title={ currRoute.title } options={ currRoute.routes } />
                        }
                    </li>

                );
                currKeyIndex++;
            }
        }
        return links;
    }

    return (

        <header className='header' style={{ display: oldItems.length > 1 ? 'none' : 'block'}}>
            <nav className='header--nav'>
                <ul>
                    <li className='header--nav__li'>
                        <NavLink className='header--nav__link-logo' to='/'>
                            <img src={process.env.PUBLIC_URL + '/img/interface/logo@2x.png'} alt='YPM004 Logo' style={{ width: '250px', height: 'auto'}} />
                        </NavLink>
                    </li>
                    { getLinks() }
                </ul>
            </nav>
        </header>
    );

}


const mapStateToProps = (state) => ({

    oldItems: state.amenities.oldItems,

});

export default connect(mapStateToProps)(ViewHeader);
