
import React from 'react';
import { data } from './dataMap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ViewMapMenu from './ViewMapMenu';
import ConnectViewMapItem from './ConnectViewMapItem';
import LoadContainer from '../../shared/LoadContainer';
import './stylesMap.scss'


export default function ViewMap () {
    
    const items = [];
    let currGroup;
    let currItem;
    let currIndex = 0;
    for (let i = 0; i < data.length; i++) { 
        currGroup = data[i].options;
        for (let j = 0; j < currGroup.length; j++) { 
            currItem = currGroup[j];
            items.push(
                <ConnectViewMapItem key={ currIndex } item={ currItem.title } />
            );
            currIndex++;
        }
    }

    return (
        <LoadContainer>
        <div className='aerial-map--transform-container'>
            <TransformWrapper
                options={{ minScale: 1, maxScale: 1.9 }}
                defaultPositionX={0}
                defaultPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <TransformComponent
                    >
                        <img className='shared--img-fill-map aerial-map--bkg' src='img/location-map/bkg--aerial-map-emptystate.jpg' width='1920' height='980' alt='Bkg' />
                        <div className='aerial-map--frg'>
                            { items }
                        </div>
                    </TransformComponent>
                    <ViewMapMenu
                        zoomInFunc={ zoomIn }
                        zoomOutFunc={ zoomOut }
                        maxScale={2}
                     />
                </>
                )}
            </TransformWrapper>
            </div>
        </LoadContainer>
    );
}