import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectItem } from '.';


function ViewMapItem ( { item, currItem, selectItem } ) {
  
  const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase(); //replace spaces with - and remove grammar
  const iconSrc = './img/location-map/bkg--aerial-map-' + itemStripped + '.jpg';

  const display = item === currItem ? 'block' : 'none'; //only if it's the currently active item

  useEffect(() => {
    selectItem(currItem);
  },[])
  return (
      <img className='shared--img-fill-map' style={ { display: display } } src={ iconSrc } width='1645' height='980' alt='item icon' />
  );
  
}


const mapStateToProps = (state) => ({
  currItem: state.aerialMap.currItem
})

const mapDispatchToProps = (dispatch) => ({
  selectItem: (currItem) => dispatch(selectItem(currItem))
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewMapItem);