import React, { useState } from 'react'
import { connect } from 'react-redux';
import { selectItem } from '.';


function ButtonAerialMapItem ( { item, currItem, selectItem } ) {
  const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase(); //replace spaces with - and remove grammar
  const iconSrc = './img/location-map/icon--' + itemStripped + '.png';
  const [icon, setIcon] = useState(iconSrc);
 
  const iconSrcSelected = './img/location-map/icon--' + itemStripped + '-selected.png';
  return (
    <button 
      className={ item === currItem ? 'aerial-map--btn__active' : 'aerial-map--btn' }
      onMouseEnter={ () => setIcon(iconSrcSelected) } 
      onMouseOut={ () => setIcon(iconSrc)}
      onClick={ () => selectItem( item ) } 
    >
      <img src={ item === currItem ? iconSrcSelected : icon } width='45' height='45' alt='item icon' onMouseEnter={ () => setIcon(iconSrcSelected) } onMouseOut={ () => setIcon(iconSrc)} />
      <span onMouseEnter={ () => setIcon(iconSrcSelected) } onMouseOut={ () => setIcon(iconSrc)}>{ item }</span>
    </button>
  );
}


const mapStateToProps = (state) => ({
  currItem: state.aerialMap.currItem
});

const mapDispatchToProps = (dispatch) => ({
  selectItem: (currItem) => dispatch(selectItem(currItem))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAerialMapItem);