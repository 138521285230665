import React from 'react';


function ViewImage ( { url } ) {
    return (
            <img src={ process.env.PUBLIC_URL + url } width='1920' height='1080' alt='' />
    );
}


export default ViewImage;