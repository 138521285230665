import React, { useRef } from 'react';
import { selectEnlarged } from '../../shared/enlarged/';
import { connect } from 'react-redux';
import { utilAnimate } from '../../util/utilAnimate';
import { addToOldItems, displayVirtual, selectLevel, setInsideNested } from '.';
import { CSSTransition } from 'react-transition-group';


function ButtonAmenitiesHotspot ( { data, onSelected, selectEnlarged, selectLevel, currItem, addToOldItems, displayVirtual } ) {
  
  const imgRef = useRef(null);
  const viewsBtn = data?.viewsBtn;
  const variableSize = data?.variableSize;

   const onMouseEnter = (event) => {
    onSelected(event.currentTarget.parentNode); //to ensure it appears over the other hotspots
    imgRef.current.style.zIndex = 3;
    utilAnimate.fadeIn(imgRef.current);
  }

  const onClick = () => {
    if (data.href.type === 'nested') {
      console.log('on click', data.href);
      selectLevel(data.href)
      addToOldItems(currItem);
    } 
    else {
      selectEnlarged( { srcList: [data.href] } );
    }
    
     
    
  }

  const onMouseLeave = () => {
    utilAnimate.fadeOut(imgRef.current);
  }

  const displayVR = () => {
    console.log(data.href);
    displayVirtual(data.href)
  }

  const renderButtons = () => {
    if (variableSize) {
      return (
        <div style={ { position: 'absolute',  left: data.left, top: data.top, width: data?.width, height: data?.height } }>
      <button style={{ width: data?.width, height: data?.height, transform: `rotate(${data.rotation}deg)` }} onClick={ onClick } onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } ></button>
      <img  className={ 'amenities--hotspot__tmb ' + data.align || '' } src={ data.src } ref={ imgRef } alt={ data.title } />
    </div>
      )
    } else if (viewsBtn) {
      return (
        <div className='amenities--hotspot' style={ { width: '200px', left: '1740px', top: '900px'} }>
              <button onClick={displayVR} style={{ width: '120px', height: '50px'}}></button>
        </div>
      )
    } else {
      return (
        <div className='amenities--hotspot' style={ { left: data.left, top: data.top } }>
      <button style={{ transform: `rotate(${data.rotation}deg)` }} className={data.nested ? 'amenities--hotspot__btn_nested' :'amenities--hotspot__btn'} onClick={ onClick } onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } ></button>
      <img  className={ 'amenities--hotspot__tmb ' + data.align || '' } src={ data.src } ref={ imgRef } alt={ data.title } />
    </div>
      )
    }
  }
  return renderButtons();

}


const mapStateToProps = (state) => ({
  currItem: state.amenities.currItem,
  oldItemDepth: state.amenities.oldItemDepth,
});
const mapDispatchToProps = (dispatch) => ({
  selectEnlarged: (data) => dispatch(selectEnlarged(data)),
  selectLevel: (currItem, oldItem) => dispatch(selectLevel(currItem, oldItem)),
  setInsideNested: (value) => dispatch(setInsideNested(value)),
  addToOldItems: (value) => dispatch(addToOldItems(value)),
  displayVirtual: (displayVR) => dispatch(displayVirtual(displayVR)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAmenitiesHotspot);