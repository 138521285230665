import produce from 'immer';
import { data } from './dataAmenities';


// Actions
const SELECT_LEVEL = 'QWR_APP/AMENITIES/SELECT_LEVEL';
const HIDE_MENU = 'QWR_APP/AMENITIES/HIDE_MENU';
const ADD_TO_OLD_ITEMS = 'QWR_APP/AMENITIES/ADD_TO_OLD_ITEMS';
const REMOVE_FROM_OLD_ITEMS = 'QWR_APP/AMENITIES/REMOVE_FROM_OLD_ITEMS'
const SET_INSIDE_NESTED = 'QWR_APP/AMENITIES/SET_INSIDE_NESTED';
const SET_OLD_ITEM_DEPTH = 'QWR_APP/AMENITIES/SET_OLD_ITEM_DEPTH';
const DISPLAY_VR = 'QWR_APP/AMENITIES/DISPLAY_VR';
// Reducer
export default function reducer (state = { displayVR: false, insideNested: false, hideMenu: false, oldItems: [], oldItemDepth: 0, currItem: data[0].levels[0] }, action = {}) {
    switch (action.type) {
        case SELECT_LEVEL :
            return produce(state, draftState => {
                console.log('CURRENTITEMITEM', action.currItem);
                draftState.currItem = action.currItem;
            });  

        case REMOVE_FROM_OLD_ITEMS: 
            return produce(state, draftState => {
                const oldItems = draftState.oldItems;
                
                let i;
                for (i = 0; i < draftState.oldItems.length; i++ ) {
                    if (action.oldItem === draftState.oldItems[i]) {
                        break;
                    }
                }

                oldItems.splice(1, i);
                draftState.oldItems = oldItems;
            } )
        case ADD_TO_OLD_ITEMS :
            return produce(state, draftState => {
                
                const arr = [...draftState.oldItems, action.oldItem];

                const result = [];

                for (let i = 0; i < arr.length; i++) {
                    let duplicate = false;
                    for (let j = 0; j < result.length; j++) {
                        if (JSON.stringify(arr[i]) === JSON.stringify(result[j])) {
                            duplicate = true;
                            break;
                        }
                    }
                        if (!duplicate) {
                        result.push(arr[i]);
                    }
                }

                console.log('array', result);
                draftState.oldItemDepth = result.length < 1 ? 0 : result.length - 2;
                console.log('from reducer old item depth', draftState.oldItemDepth);
                draftState.oldItems = result;
            });
        case SET_OLD_ITEM_DEPTH:
            return produce(state, draftState => {
                draftState.oldItemDepth = action.oldItemDepth;
            })
        case SET_INSIDE_NESTED :
            return produce(state, draftState => {
                draftState.insideNested = action.insideNested;
            });  
        case HIDE_MENU :
            return produce(state, draftState => {
                draftState.hideMenu = action.hideMenu;
            });  
        case DISPLAY_VR:
            return produce(state, draftState => {
                draftState.displayVR = action.displayVR;
            });
        default :
            return state;
    }
}


// Action Creators
export const selectLevel = (currItem) => ( {
    type: SELECT_LEVEL,
    currItem,
} );

export const selectHideMenu = (hideMenu) => ({
    type: HIDE_MENU,
    hideMenu,
})

export const addToOldItems = (oldItem) => ({
    type: ADD_TO_OLD_ITEMS,
    oldItem
})
export const removeFromOldItems = (oldItem) => ({
    type: REMOVE_FROM_OLD_ITEMS,
    oldItem
})

export const setOldItemDepth = (oldItemDepth) => ({
    type: SET_OLD_ITEM_DEPTH,
    oldItemDepth,
})
export const setInsideNested = (insideNested) => ({
    type: SET_INSIDE_NESTED,
    insideNested,
})


// displayVR will either be a string or false.
export const displayVirtual = (displayVR) => ({
    type: DISPLAY_VR,
    displayVR,
})