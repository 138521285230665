import React, { useEffect, useRef } from 'react';
import LoadContainer from '../../shared/LoadContainer';
import ViewAmenitiesUI from './ViewAmenitiesUI';
import ViewAmenitiesLevel from './ConnectViewAmenitiesLevel';
import './stylesAmenities.scss';
import { selectLevel } from '.';
import { connect } from 'react-redux';
import ConnectViewAmenitiesGallery from './ConnectViewAmenitiesGallery';
import View3dAPP from './ViewTour_H3SA';
import { utilAnimate } from "../../util/utilAnimate";


const ViewAmenities = ({ data, selectLevel, displayVR, url }) => {
    const containerRef = useRef(null);

    const getLevels = () => {
        const levels = [];
        let levelData;
        for (let i = 0; i < data[0].levels.length; i++) { 
            levelData = data[0].levels[i];

            if (data[0].levels[i].gallery) {
                levels.push(
                    <ConnectViewAmenitiesGallery key={ levelData.index } levelData={ levelData } />
                )
            }

            
            levels.push(
                <ViewAmenitiesLevel key={ levelData.index } levelData={ levelData } />
            );

            for (var j = 0; j < data[0].levels[i].hotSpots?.length; j++) {
                levelData = data[0].levels[i].hotSpots[j];

                levels.push(
                    <ViewAmenitiesLevel key={ Math.random() * 1000 } levelData={ levelData } />
                );
            }
        }
        return levels;
    }

    useEffect(() => {
        selectLevel(data[0].levels[0])
    }, [data, selectLevel]);

    useEffect(() => {
        if (displayVR) {
            utilAnimate.fadeIn(containerRef.current);

        }
    }, []);

    return (
        !!displayVR ? <div ref={containerRef} ><View3dAPP url={displayVR} /></div> : (
            <LoadContainer style={{ position: 'absoloute', zIndex: 100}}>
            <div className='amenities--levels' key={Math.random() * 1000}>
                { getLevels() }
            </div>
            <ViewAmenitiesUI id={data[0].id} />
        </LoadContainer>
        )
       
    );

}

const mapStateToProps = (state) => ({
    displayVR: state.amenities.displayVR,
});

const mapDispatchToProps = (dispatch) => ({
    selectLevel: (currItem) => dispatch(selectLevel(currItem))
  
  });
export default connect(mapStateToProps, mapDispatchToProps)(ViewAmenities);

