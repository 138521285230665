import React from 'react';
import ViewSlider from '../../app/components/slider/ViewSlider';
import ConnectViewGallery from '../../app/components/gallery/ConnectViewGallery';

export default function ViewGalleryBuilding ({ data }) {
    const slideList = data.map(d => {
        return {
            type: 'raw',
            slide: <ConnectViewGallery data={ d } />,
        }
    });
    

    const settings = {
        autoplay: false,
		speed: 500,
		infinite: false,
        dotsClass: 'slick-dots slick-dots__low',
        dots: false,
	};

    return (
        <ViewSlider lightBackground={true} className="slider--transform-container" data={ { slideList: slideList, sliderOptions: settings } } />
    );

}