
import React from 'react';
import ViewHome from './home/ViewHome';
import ViewGalleryLocationLifestyle from './location-lifestyle/ViewGalleryLocationLifestyle';
import ViewMap from './map/ViewMap';

import { locationlifestyle1, locationlifestyle2, amenities, building, team1, team2, team3, team4, team5, design1, design2, design3, design4, design5, design6, design7, design8, design9, design10, design11, design12, design13, design14, design15, interiorsImages1, interiorsImages2, interiorsImages3, interiorsImages4, interiorsImages5, interiorsImages6, interiorsImages7, interiorsImages8, interiorsImages9, interiorsFinishes1, interiorsFinishes2, interiorsFinishes3, interiorsFinishes4, interiorsFinishes5, interiorsFinishes6, interiorsFinishes7, interiorsDetail1, interiorsDetail2, interiorsDetail3, interiorsDetail4, interiorsDetail5, interiorsDetail6, interiorsDetail7, interiorsDetail8, interiorsDetail9, interiorsDetail10, interiorsDetail11, interiorsDetail12, interiorsDetail13, interiorsDetail14, interiorsDetail15, interiorsDetail16, interiorsDetail17, interiorsDetail18, interiorsDetail19, interiorsDetail20, interiorsDetail21, interiorsDetail22, interiorsDetail23, interiorsDetail24, interiorsDetail25, interiorsDetail26, interiorsDetail27, interiorsDetail28, interiorsDetail29, interiorsDetail30, interiorsDetail31, interiorsDetail32, building2, elevations1, elevations2, elevations4, elevations5, elevations3, amenities2 } from './gallery/data/gallery';


import ViewInteriorsImages from './interiors-images/ViewInteriorsImages';
import ViewInteriorsFinishes from './interiors-finishes/ViewInteriorsFinishes';
import ViewInteriorsDetail from './interiors-detail/ViewInteriorsDetail';
import ViewGalleryElevations from './elevations/ViewGalleryElevations'
import ViewGalleryTeam from './team/ViewGalleryTeam';

import ViewGalleryBuilding from './gallery-building/ViewGalleryBuilding';
import ViewGalleryAmenities from './gallery-amenities/ViewGalleryAmenities';
import ViewGalleryFlythrough from './gallery-fly-through/ViewGalleryFlythrough';
import ViewGalleryDesign from './design/ViewGalleryDesign';
import ViewMovies from './movies/ViewMovies';
import ViewImage from '../app/components/viewImage/ViewImage';
import ViewAmenities from './amenities/ViewAmenities';
// import ViewAmenities from './amenities/ViewAmenities';
import { data as dataAmenities } from './amenities/dataAmenities';
// import { data as dataResidences } from './residences/dataResidences';
// import ViewResidences from './residences/ViewResidences';
export const dataRoutes = [
    {
        title: 'Video',
        path: '/video',
        component: ViewMovies,
    },
        {
        title: 'Location',
        routes: [
            {
                title: 'Lifestyle',
                path: '/location-lifestyle',
                component: ViewGalleryLocationLifestyle,
                data: [locationlifestyle1, locationlifestyle2],
            },
            {
                title: 'Map',
                path: '/location-map',
                component: ViewMap,
            },
        ]
    },
    {

        title: 'Team',
        path: '/team',
        component: ViewGalleryTeam,
        data: [team1, team2, team3, team4],
    },
    {
        title: 'Design',
        path: '/design',
        component: ViewGalleryDesign,
        data: [design1, design2, design3, design4 ,design5 ,design6, design7, design8, design9, design10, design11, design12, design13, design14],
    },
    {
        title: 'Interiors',
        routes: [
            {
                title: 'Renders',
                path: '/interiors-images',
                component: ViewInteriorsImages,
                data: [interiorsImages1, interiorsImages2, interiorsImages3, interiorsImages4, interiorsImages5, interiorsImages6, interiorsImages7, interiorsImages8/*,interiorsImages9*/],
            },

            {
                title: 'Interiors',
                path: '/interiors-detail',
                component: ViewInteriorsDetail,
                data: [interiorsDetail1, interiorsDetail2, interiorsDetail3, interiorsDetail4, interiorsDetail5, interiorsDetail6, interiorsDetail7, interiorsDetail8, interiorsDetail9 ,interiorsDetail10, interiorsDetail11, interiorsDetail12, interiorsDetail13, interiorsDetail14, interiorsDetail15],
            },

            {
                title: 'Schedule',
                path: '/interiors-finishes',
                component: ViewInteriorsFinishes,
                data: [interiorsFinishes1, interiorsFinishes2, interiorsFinishes3, interiorsFinishes4, interiorsFinishes5],
            },

        ]
    },
    // {
    //     title: 'Residences',
    //     path: '/residences',
    //     component: ViewAmenities,
    //     data: dataAmenities

    //},
    {
        title: 'Masterplan',
        path: '/residences',
         routes: [
            {
                title: 'Residences',
                path: '/plans',
                component: ViewAmenities,
                data: dataAmenities
            },
            {
                title: 'Elevations',
                path: '/elevations',
                component: ViewGalleryElevations,
                data: [elevations1, elevations2, elevations3, elevations4, elevations5],
            },
        ]
    },
    {

        title: 'Gallery',
        routes: [
            {
                title: 'Residences',
                path: '/gallery-building',
                component: ViewGalleryBuilding,
                data: [building, building2],
            },
            {
                title: 'Amenities',
                path: '/gallery-amenities',
                component: ViewGalleryAmenities,
                data: [amenities, amenities2],
            },
            /*
            {
                title: 'Video',
                path: '/gallery-video',
                component: ViewGalleryFlythrough
            }
                */
        ]
    },
    {
        title: 'Home',
        path: '/',
        component: ViewHome
    }

];