
import React from 'react';
import { data } from './dataAmenities';
import ConnectButtonMapItem from './ConnectButtonMapItem';
import { connect } from 'react-redux';


const ViewAmenitiesUI = ( { id, hideMenu } ) => {

    const getButtons = () => {
        const btns = [];
        let levelData;
        for (let i = 0; i < data[0].levels.length; i++) { 
            levelData = data[0].levels[i];
            if (levelData.inMenu) {
                btns.push(
                    <li key={ i }>
                        
                        <ConnectButtonMapItem marginTop={levelData.marginTop} levelData={ levelData } key={levelData.id } item={ levelData.title } />
    
                    </li>
                );
            }
          
        }
        return btns;
    }

    return (
        <div className={ hideMenu ? 'amenities-hide': 'amenities--ui'}>
            <ul>
                { getButtons() }
            </ul>
        </div>
    );

}

const mapStateToProps = (state) => ({
    hideMenu: state.amenities.hideMenu,
});

export default connect(mapStateToProps)(ViewAmenitiesUI);