import React, { useEffect } from 'react';
import { displayVirtual, setMounted } from '.';
import { connect } from 'react-redux';



const View3dAPP = ({ url, displayVirtual}) => {
        const handleClose = () => {
          displayVirtual(false)
        }
        return(
            <div>
                <button style={{ position: 'absolute', zIndex: 100, right: '50px'}} className='enlarged--close-btn-amenities-black' onClick={handleClose}>Close</button>
                <iframe src={process.env.PUBLIC_URL + url} frameBorder={0} style={{ position: 'absolute', overflow: 'hidden', height: '1084px', width: '100%'}} height="1084px" width="100%" title="3DView" />
             </div>
        );
  }


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  displayVirtual: (displayVR, url) => dispatch(displayVirtual(displayVR, url)),
  
});



export default connect(mapStateToProps, mapDispatchToProps)(View3dAPP);
//Photo by Sharon McCutcheon on Unsplash
//https://unsplash.com/photos/-vgLTTWPesg
