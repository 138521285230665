
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import ConnectButtonAmenitiesHotspot from './ConnectButtonAmenitiesHotspot'
import { addToOldItems, removeFromOldItems, selectHideMenu, selectLevel, setOldItemDepth } from '.';
import './stylesAmenities.scss';
import { CSSTransition } from 'react-transition-group';

function ViewAmenitiesLevel ( { levelData, currItem, addToOldItems, selectHideMenu, hideMenu, oldItems, oldItemDepth, selectLevel, setOldItemDepth, removeFromOldItems} ) {
    const display = levelData.title === currItem.title ? 'block' : 'none'; //only if it's the currently active item
    
    const getHotspots = () => {
        const btnList = [];
        const dataList = currItem.hotSpots; 
        if (dataList) {
            for (let i = 0; i < dataList.length; i++) {
               
                btnList.push(
                    <ConnectButtonAmenitiesHotspot levelData={levelData} key={ levelData.index } data={ dataList[i] } onSelected={ onHotspotSelected } />
                )
            }
            return btnList;
        }
        
    } 

    useEffect(() => {
        if (display) {
            addToOldItems(currItem);
        }
    }, [])

    const hotspotsContainer = useRef(null);
    const onHotspotSelected = (hotspot) => {
        hotspotsContainer.current.append(hotspot); //to ensure it appears over the other hotspots
    }

    return (
        <div style={ { display: display } }>
            { hideMenu && <button  className={ levelData.title === 'club-2' || oldItemDepth > 0 ? 'enlarged--close-btn-amenities-black-top' : 'enlarged--close-btn-amenities-white'} onClick={ () => { selectLevel(oldItems[oldItemDepth]); removeFromOldItems(oldItems[oldItemDepth]); setOldItemDepth(oldItemDepth - 1);  selectHideMenu(oldItemDepth > 0); }} >CLOSE</button> }
            
            <img src={ currItem.src } alt='amenities view' style={{ marginTop: '0px' }} />
            
            <div className='features--hotspots-container' ref={ hotspotsContainer }>
                { getHotspots() }
            </div>
        </div>

    );

}


const mapStateToProps = (state) => ({
    currItem: state.amenities.currItem,
    oldItemDepth: state.amenities.oldItemDepth,
    oldItems: state.amenities.oldItems,
    hideMenu: state.amenities.hideMenu,
    insideNested: state.amenities.insideNested,
});

const mapDispatchToProps = (dispatch) => ({
    selectLevel: (currItem) => dispatch(selectLevel(currItem)),
    selectHideMenu: (value) => dispatch(selectHideMenu(value)),
    addToOldItems: (value) => dispatch(addToOldItems(value)),
    setOldItemDepth: (value) => dispatch(setOldItemDepth(value)),
    removeFromOldItems: (value) => dispatch(removeFromOldItems(value))
  });
export default connect(mapStateToProps, mapDispatchToProps)(ViewAmenitiesLevel);