
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { addToOldItems, selectHideMenu, selectLevel } from '.';
import { data } from './dataAmenities';

function ButtonAerialMapItem ( { levelData, currItem, selectLevel, selectHideMenu, oldItems, addToOldItems, marginTop } ) {
 
  const [icon, setIcon] = useState(levelData.iconUp);
  const iconSrc = levelData.iconUp;
  const iconSrcSelected = levelData.iconDown;

  const isAClub = levelData.title === 'A CLUB' ? data[0].levels[1] : false; 

  useEffect(() => {
    if (!oldItems.includes(currItem)) {
      addToOldItems(currItem);
    }
  }, [addToOldItems, oldItems, currItem])
    return (
    <button 
      style={{ width: '270px', height: '300px', marginTop: marginTop, marginLeft: '-30px', marginRight: '20px'}} 
    className={ levelData.title === currItem.title ? 'amenities--ui__btn-active' : 'amenities--ui__btn' }
      onMouseEnter={ () => setIcon(iconSrcSelected) } 
      onMouseOut={ () => setIcon(iconSrc)}
      onClick={ () => { selectHideMenu(true) ; selectLevel( isAClub ? isAClub :levelData )}} 
    />
    
  );
}


const mapStateToProps = (state) => ({
  currItem: state.amenities.currItem,
  oldItems: state.amenities.oldItems,
});

const mapDispatchToProps = (dispatch) => ({
  selectLevel: (currItem, oldItem) => dispatch(selectLevel(currItem, oldItem)),
  selectHideMenu: (value) => dispatch(selectHideMenu(value)),
  addToOldItems: (value) => dispatch(addToOldItems(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAerialMapItem);