import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import './stylesAmenities.scss';
import ConnectButtonAmenitiesHotspot from './ConnectButtonAmenitiesHotspot';
const ConnectViewAmenitiesGallery = ({ levelData, currItem}) => {
  const display = levelData.title === currItem.title ? 'block' : 'none'; //only if it's the currently active item
  const [imgSrc, setImgSrc] = useState(levelData.srcOne);
  const [hotspots, setHotspots]  = useState(levelData.hotSpotsOne)
  const rotateImages = () => {
    if (imgSrc === levelData.srcOne) {
      setImgSrc(levelData.srcTwo)
      setHotspots(levelData.hotSpotsTwo)
    } else {
      setImgSrc(levelData.srcOne)
      setHotspots(levelData.hotSpotsOne)


    }
  }

  const getHotspots = () => {
    const btnList = [];

    if (hotspots) {
        for (let i = 0; i < hotspots.length; i++) {
           
            btnList.push(
                <ConnectButtonAmenitiesHotspot levelData={levelData} key={ levelData.index } data={ hotspots[i] } onSelected={ onHotspotSelected } />
            )
        }
        return btnList;
    }
    
} 

const hotspotsContainer = useRef(null);
    const onHotspotSelected = (hotspot) => {
        hotspotsContainer.current.append(hotspot); //to ensure it appears over the other hotspots
    }

  return (
    <div style={{ display: display, marginTop: '-25px'}}>
      <button onClick={() => rotateImages()}><img src={process.env.PUBLIC_URL + '/img/interface/btn--submenu-menu.png'} className='left-arrow'/></button>
      <button onClick={() => rotateImages()}><img src={process.env.PUBLIC_URL + '/img/interface/btn--submenu-menu.png'} className='right-arrow'/></button>

      <div className='features--hotspots-container' ref={ hotspotsContainer }>
        { getHotspots() }
      </div>
      <img src={imgSrc} />
      
    </div>
  )
}

const mapStateToProps = (state) => ({
  currItem: state.amenities.currItem,
});
export default connect(mapStateToProps)(ConnectViewAmenitiesGallery);